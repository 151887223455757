<template>
    <div id="tags-view-container" class="tags-view-container">
        <scroll-pane ref="scrollPane" class="tags-view-wrapper" @scroll="handleScroll">
            <router-link
                    v-for="tag in visitedViews"
                    ref="tag"
                    :key="tag.fullPath"
                    :class="isActive(tag)?'active':''"
                    :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
                    tag="span"
                    class="tags-view-item"
                    :style="{background: isActive(tag)? defaultTheme: '', borderColor: isActive(tag)? defaultTheme: ''}"
                    v-slot="{ navigate }"
                    @contextmenu.prevent.native="openMenu(tag,$event)"
                    @click.middle.native="!isAffix(tag)?closeSelectedTag(tag):''"

            >
        <span @click="navigate" @keypress.enter="navigate">
          {{tag.query&&tag.query.title || tag.title }}
          <span v-if="!isAffix(tag) && !(visitedViews.length <= 1 && indexType == '1')" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)"/>
        </span>
            </router-link>
        </scroll-pane>
        <ul v-show="visible" :style="{left:left+'px',top:top -60 +'px'}" class="contextmenu">
            <li v-if="isActive(selectedTag)" @click="refreshSelectedTag(selectedTag)">刷新</li>
            <li v-if="!isAffix(selectedTag) && !(visitedViews.length <= 1 && indexType == '1')" @click="closeSelectedTag(selectedTag)">关闭</li>
            <li @click="closeOthersTags">关闭其它</li>
            <li @click="closeAllTags(selectedTag)" v-if="indexType != '1'">关闭所有</li>
        </ul>
    </div>
</template>

<script>
    import ScrollPane from './ScrollPane'
    import path from 'path'

    export default {
        components: {ScrollPane},
        data() {
            return {
                indexType: sessionStorage.getItem('indexType') || '',
                visible: false, // 右键显示
                top: 0,
                left: 0,
                selectedTag: {},
                affixTags: []
            }
        },
        computed: {
            // 主题色
            defaultTheme() {
                if (this.$store.state.config.defaultTheme) {
                    return this.$store.state.config.defaultTheme
                } else {
                    return '#498eff'
                }
            },
            // 页签
            visitedViews() {
                return this.$store.state.tagsView.visitedViews
            },
            // 路由
            routes() {
                return JSON.parse(sessionStorage.getItem('dynamicMenuRoutes'))
            }
        },
        watch: {
            // 监听路由
            $route() {
                this.addTags()
                this.moveToCurrentTag()
            },
            visible(value) {
                if (value) {
                    document.body.addEventListener('click', this.closeMenu)
                } else {
                    document.body.removeEventListener('click', this.closeMenu)
                }
            }
        },
        mounted() {
            console.log(this.indexType);
            this.initTags()
            this.addTags()
        },
        methods: {
            // 当前页签
            isActive(route) {
                return route.path === this.$route.path
            },
            isAffix(tag) {
                return tag.name == 'home'
            },
            filterAffixTags(routes, basePath = '/') {
                let tags = []
                routes.forEach(route => {
                    if (route.meta && route.meta.affix) {
                        const tagPath = path.resolve(basePath, route.path)
                        tags.push({
                            fullPath: tagPath,
                            path: tagPath,
                            name: route.name,
                            meta: {...route.meta}
                        })
                    }
                    if (route.children) {
                        const tempTags = this.filterAffixTags(route.children, route.fullPath)
                        if (tempTags.length >= 1) {
                            tags = [...tags, ...tempTags]
                        }
                    }
                })
                return tags
            },
            // 初始化页签
            initTags() {
                const affixTags = this.affixTags = this.filterAffixTags(this.routes)
                for (const tag of affixTags) {
                    // Must have tag name
                    if (tag.name) {
                        this.$store.dispatch('tagsView/addVisitedView', tag)
                    }
                }
            },
            // 添加页签
            addTags() {
                let that = this;
                const {name} = this.$route
                let pathName = this.$store.state.tagsView.cachedViews
                if (pathName.indexOf(that.$route.name) == -1 && name) {
                    this.$store.dispatch('tagsView/addView', this.$route)
                }
                /*if (name) {
                  this.$store.dispatch('tagsView/addView', this.$route)
                }*/
                return false
            },
            // 跳到当前页签
            moveToCurrentTag() {
                const tags = this.$refs.tag
                this.$nextTick(() => {
                    for (const tag of tags) {
                        if (tag.to.path === this.$route.path) {
                            this.$refs.scrollPane.moveToTarget(tag)
                            if (tag.to.path !== this.$route.path) {
                                this.$store.dispatch('tagsView/updateVisitedView', this.$route)
                            }
                            break
                        }
                    }
                })
            },
            // 刷新
            refreshSelectedTag(view) {
                this.$store.dispatch('tagsView/delCachedView', view).then(() => {
                    const {fullPath} = view
                    this.$nextTick(() => {
                        // this.$router.replace({
                        //     path: '/redirect' + fullPath
                        // })
                        this.$router.go(0)
                    })
                })
            },
            // 关闭选中的页签
            closeSelectedTag(view) {
                this.$store.dispatch('tagsView/delView', view).then(({visitedViews}) => {
                    if (this.isActive(view)) {
                        this.toLastView(visitedViews, view)
                    }
                })
            },
            // 关闭其他页签
            closeOthersTags() {
                this.$router.push(this.selectedTag)
                this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
                    this.moveToCurrentTag()
                })
            },
            // 关闭所有页签
            closeAllTags(view) {
                this.$store.dispatch('tagsView/delAllViews').then(({visitedViews}) => {
                    if (this.affixTags.some(tag => tag.fullPath === view.fullPath)) {
                        return
                    }
                    this.toLastView(visitedViews, view)
                })
            },
            // 判断是否是最后一个页签
            toLastView(visitedViews) {
                const latestView = visitedViews.slice(-1)[0]
                if (latestView) {
                    this.$router.push(latestView.fullPath)
                } else {
                    this.$router.push('/menuHome')
                }
            },
            // 点击页签
            openMenu(tag, e) {
                const menuMinWidth = 105
                const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
                const offsetWidth = this.$el.offsetWidth // container width
                const maxLeft = offsetWidth - menuMinWidth // left boundary
                const left = e.clientX - offsetLeft + 15 // 15: margin right

                if (left > maxLeft) {
                    this.left = maxLeft
                } else {
                    this.left = left
                }

                this.top = e.clientY
                this.visible = true
                this.selectedTag = tag
            },
            // 关闭页签
            closeMenu() {
                this.visible = false
            },
            // 页签滚动
            handleScroll() {
                this.closeMenu()
            }
        }
    }
</script>
